jQuery(document).ready(function($) {

	$('.cmb2_select, .select2, .shiftee-calendar-filter').each( function() {
		var isMultiple = $(this).attr('multiple');
		var placeholder = $(this).attr('placeholder') || 'Choose an option';
		$(this).select2({ 
			width: 'resolve',
			placeholder: placeholder,
			minimumResultsForSearch: 10,
			closeOnSelect: !isMultiple,
		});
	});

	$('.wpdm-cat-select').each( function() {
		$(this).select2({ 
			width: 'resolve',
			placeholder: 'Choose a category',
			minimumResultsForSearch: 10,
			closeOnSelect: true,
		});
	}).on('change.select2', function() {
		window.location = $(this).val();
	});

	if ($('.member-table').length && typeof $.fn.DataTable !== 'undefined' ) {
		$('.member-table').DataTable({
			paging: false,
			searching: false,
			info: false,
			stateSave: true,
			scrollX: true,
		});
	}

	$('.saved-searches button').on('click', function(e) {
		if ( $(e.target).is('.delete') ) {
			return false;
		}

		var form = $('#form_search_members'),
			search = $(this).data('search'),
			query = search[0],
			columns = search[1];

		clearCurrentForm($('#query-clear'));

		for (var i = 0; i < query.search_field.length - 1; ++i) {
			form.find('.cmb-type-member-search .search-term-row .add-row').last().trigger('click');
		}

		form.find('.cmb-type-member-search .search-term-row').each(function(i, el) {
			$(this).find('.search-relation select').val(query.relation[i]).change();
			$(this).find('.search-field select').val(query.search_field[i]).trigger('change', {
				searchTerm: query.search_term[i],
			});
		});

		form.find('#columns').val(columns).change();
		form.find('#save_search').prop('checked', true).change();
		form.find('#save_search_label').val($(this).find('.search-label').text());
	});


	$('.saved-searches button .delete').on('click', function(e) {
		e.preventDefault();

		if ( !window.confirm( "Are you sure you want to delete this search?" ) ) {
			return false;
		}

		var button = $(this).parent('button').prop('disabled', true);
		var search = button.data('search');

		$.ajax({
			url: ajax_url,
			data: {
				action: 'fdmu_delete_search',
				query: search[0],
				columns: search[1],
			},
			type: 'POST',
		}).done(function(data, status) {
			button.fadeOut().remove();
		}).fail(function(xq, status, err) {
			button.prop('disabled', false);
			console.log(err);
		});
	});
});


function clearCurrentForm(el) {
	var $this = jQuery(el);

	$this.closest('.cmb-form').find('input[type="text"], select, textarea').val('');
	$this.closest('.cmb-form').find('.cmb2_select, select.select2').change();
	$this.closest('.cmb-form').find('input[type="checkbox"]').prop('checked', false).change();

	if ( $this.siblings('.member-search-fields') ) {
		$this.siblings('.member-search-fields').find('.search-term-row .remove-row').not(':first').trigger('click');
	}
}
